<template>
  <div class="inner-section">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ checkBn ? 'স্থানীয়/আন্তর্জাতিক বাণিজ্য মেলার আবেদন ফরম' : 'Local/International Trade Fair Application Form' }}</h4>
      </template>
      <template v-slot:body>
        <div class="service-description">
          <b-container>
            <body-card>
              <!-- application start -->
              <template v-slot:body>
                <b-overlay :show="loading">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(finalSave)" @reset.prevent="reset" autocomplete="off">
                            <div class="application-form-wrapper">
                                <div class="application-itmes">
                                    <b-row>
                                        <b-col sm="12">
                                            <div class="group-form-card">
                                                <b-card>
                                                        <b-row>
                                                            <b-col class="ml-3 pl-3 mr-3 mt-5">
                                                                <ValidationProvider name="Applicant Type" vid="applicant_type" rules="required|min_value:1">
                                                                    <b-form-group label-for="applicant_type" label-cols-sm="4" slot-scope="{ valid, errors }">
                                                                        <template v-slot:label>
                                                                         {{ $t('externalTradeFair.applicant_type') }} <span class="text-danger">*</span>
                                                                        </template>
                                                                        <b-form-radio-group v-model="formData.applicant_type"
                                                                            :options="applicantTypeList"
                                                                            id="applicant_type"
                                                                            value-field="value" text-field="text"
                                                                            :state="errors[0] ? false : (valid ? true : null)"></b-form-radio-group>
                                                                        <div class="invalid-feedback d-block">
                                                                            {{ errors[0] }}
                                                                        </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                        </b-row>
                                                        <b-row>
                                                            <b-col class="ml-5 pl-5 mr-3 mt-5">
                                                                <div class="mb-5 section-head">
                                                                    <span>{{ checkBn ? 'বরাবর' : 'To' }}</span><br>
                                                                    <span>{{ checkBn ? 'সিনিয়র সচিব/সচিব' : 'SeniorSecretary/Secretary' }}</span><br>
                                                                    <span>{{ checkBn ? 'বাণিজ্য মন্ত্রণালয়' : 'Ministry of Commerce' }}</span><br>
                                                                    <span>{{ checkBn ? 'বাংলাদেশ সচিবালয়, ঢাকা।' : 'Bangladesh Secretariat, Dhaka.' }}</span>
                                                                </div>
                                                                <div class="section-subject">
                                                                    <b-row>
                                                                        <div class="col-sm-1 font-weight-bold" style="max-width: 10.333333% !important;">{{ checkBn ? 'বিষয়' : 'Subject' }}: </div>
                                                                     <div class="col-sm-5">
                                                                            <ValidationProvider name="Subject" vid="subject" rules="required">
                                                                                <b-form-group slot-scope="{ valid, errors }">
                                                                                    <b-form-input id="subject" v-model="formData.subject"
                                                                                        :placeholder="checkBn ? 'এখানে লিখুন' : 'Write here'"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div style="max-width: 49% !important;" class="col-sm-6">{{ checkBn ? 'শীর্ষক স্থানীয়/আন্তর্জাতিক বাণিজ্য মেলা আয়োজনের অনুমতি প্রদান।' : 'grant of permission for local/international trade fairs.'}} </div>
                                                                    </b-row>
                                                                </div>
                                                                <div class="mb-2 mt-4">{{ checkBn ? 'মহোদয়' : 'Dear Sir' }},</div>
                                                                <div class="section-body mt-2">
                                                                    <b-row>
                                                                        <div :class="checkBn ? 'col-sm-3' : 'col-sm-3'">{{ checkBn ? 'সবিনয় নিবেদন এই যে, আমি' : 'I am interested at location' }} </div>
                                                                        <div :class="checkBn ? 'col-sm-4' : 'col-sm-4'">
                                                                            <ValidationProvider name="Applicant Name" vid="applicant_name" rules="required">
                                                                                <b-form-group slot-scope="{ valid, errors }">
                                                                                    <b-form-input id="applicant_name" v-model="formData.applicant_name"
                                                                                        :placeholder="checkBn ? 'এখানে আবেদনকারীর নাম লিখুন' : 'Write Applicant Name here'"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div :class="checkBn ? 'col-sm-5' : 'col-sm-5'">
                                                                            <ValidationProvider name="Place" vid="place" rules="required">
                                                                                <b-form-group slot-scope="{ valid, errors }">
                                                                                    <b-form-input id="place" v-model="formData.place"
                                                                                        :placeholder="checkBn ? 'এখানে স্থান লিখুন' : 'Place Write here'"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                    </b-row>
                                                                    <b-row>
                                                                        <div :class="checkBn ? 'col-sm-1' : 'col-sm-5'">{{ checkBn ? 'স্থানে' : 'for organizing Local/International Trade Fair  from' }} </div>
                                                                        <div :class="checkBn ? 'col-sm-4' : 'col-sm-3'">
                                                                            <ValidationProvider name="From Date" vid="from_date" rules="required" v-slot="{ errors }">
                                                                                <b-form-group>
                                                                                    <date-picker id="from_date" class="form-control"
                                                                                        v-model="formData.from_date"
                                                                                        :class="errors[0] ? 'is-invalid' : ''"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                        :placeholder="$t('globalTrans.select')">
                                                                                    </date-picker>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div :class="checkBn ? 'col-sm-2' : 'col-sm-1'">{{ checkBn ? 'তারিখ থেকে' : 'to' }} </div>
                                                                        <div :class="checkBn ? 'col-sm-4' : 'col-sm-3'">
                                                                            <ValidationProvider name="To Date" vid="to_date" rules="required" v-slot="{ errors }">
                                                                                <b-form-group>
                                                                                    <date-picker id="to_date" class="form-control"
                                                                                        v-model="formData.to_date"
                                                                                        :class="errors[0] ? 'is-invalid' : ''"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                        :placeholder="$t('globalTrans.select')">
                                                                                    </date-picker>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div v-if="checkBn" class="col-sm-1"> {{ 'পর্যন্ত' }}</div>
                                                                    </b-row>
                                                                    <b-row>
                                                                        <div v-if="!checkBn" class="col-sm-1"> {{ 'name' }}</div>
                                                                        <div class="col-sm-11">
                                                                            <ValidationProvider name="Fair Name" vid="fair_name" rules="required">
                                                                                <b-form-group slot-scope="{ valid, errors }">
                                                                                <b-form-input                   id="fair_name"                  v-model="formData.fair_name"
                                                                                    :placeholder="checkBn ? 'এখানে মেলার নাম লিখুন' : 'Write Fair Name here'"
                                                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div v-if="checkBn" class="col-sm-1"> {{ 'শীর্ষক' }}</div>
                                                                        <div class="col-sm-4">
                                                                            <ValidationProvider name="Fair Type" vid="fair_type" rules="required|min_value:1">
                                                                                <b-form-group label-for="fair_type" label-cols-sm="1" slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                    </template>
                                                                                    <b-form-radio-group v-model="formData.fair_type"
                                                                                        :options="fairTypeList"
                                                                                        id="fair_type"
                                                                                        value-field="value" text-field="text"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-radio-group>
                                                                                    <div class="invalid-feedback d-block">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div class="col-sm-7 text-justify">{{ checkBn ? 'বাণিজ্য মেলা আয়োজন করতে আগ্রহী।' : 'I have presented the necessary documents and information in this regard for your kind consideration and necessary action.'}}</div>
                                                                        <div class="col-sm-12 text-justify">{{ checkBn ? 'এ বিষয়ে প্রয়োজনীয় কাগজপত্র ও তথ্যাদি আপনার সদয় বিবেচনা ও প্রয়োজনীয় পদক্ষেপ গ্রহণের জন্য উপস্থাপন করলাম।' : 'I have presented the necessary documents and information in this regard for your kind consideration and necessary action.'}}</div>
                                                                    </b-row>
                                                                    <br>
                                                                    <br>
                                                                    <b-row>
                                                                        <div class="col-sm-3">
                                                                            <ValidationProvider name="Designation" vid="designation" rules="required">
                                                                                <b-form-group slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                    {{ $t('portal.applicant_designation') }}
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        id="designation"
                                                                                        v-model="formData.designation"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                        >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                    {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div class="col-sm-3">
                                                                            <ValidationProvider name="Applicant Mobile" vid="applicant_mobile" rules="required|min:11|max:11">
                                                                                <b-form-group slot-scope="{ valid, errors }">
                                                                                    <template v-slot:label>
                                                                                    {{ $t('portal.applicant_mobile') }}
                                                                                    </template>
                                                                                    <b-form-input
                                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                                        id="applicant_mobile"
                                                                                        v-model="formData.applicant_mobile"
                                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                                        >
                                                                                    </b-form-input>
                                                                                    <div class="invalid-feedback">
                                                                                    {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <b-row>
                                                                                <div class="col-sm-6"></div>
                                                                                <div class="col-sm-6 text-center">
                                                                                    <span>{{ checkBn ? 'বিনীত নিবেদক' : 'Regards-' }}</span><br><br>
                                                                                    <ValidationProvider name="Signature" vid="signature_attachment" rules="required" v-slot="{ errors }">
                                                                                        <b-form-group label-for="signature_attachment">
                                                                                            <b-form-file
                                                                                                id="signature_attachment"
                                                                                                v-model="formData.signature_attachment"
                                                                                                @change="onFileChange($event, 'signature_attachment')"
                                                                                                accept=".jpg,.jpeg"
                                                                                                plain
                                                                                                :state="errors[0] ? false : (valid ? true : null)">
                                                                                            </b-form-file>
                                                                                            <div class="invalid-feedback">
                                                                                                {{ errors[0] }}
                                                                                            </div>
                                                                                        </b-form-group>
                                                                                    </ValidationProvider>
                                                                                    <span>{{ checkBn ? 'স্বাক্ষর' : 'Signature' }}</span>
                                                                                </div>
                                                                            </b-row>
                                                                        </div>
                                                                    </b-row><br><br>
                                                                    <b-row v-if="formData.applicant_type">
                                                                        <b-col sm="12">
                                                                            <span class="font-weight-bold">{{ checkBn ? 'সংযুক্তি' : 'Attachment' }}:</span> (<span style="font-size: 14px">{{ checkBn ? 'ফাইলের ধরণ pdf হতে হবে।' : 'File type must be format of pdf' }}</span>)
                                                                        </b-col>
                                                                        <!-- <b-col sm="12" v-if="formData.applicant_type === 1">
                                                                            <ValidationProvider name="Copy of application fee payment challan" vid="payment_challan_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="payment_challan_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(1) + '. ' + (checkBn ? 'মেলা পরিপত্র অনুযায়ী আবেদন ফি জমা প্রদানের চালানের কপি (ফি’র বর্ণনা)' : 'Copy of application fee payment challan as per fair circular (Description of Fees)') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="payment_challan_attachment"
                                                                                        v-model="formData.payment_challan_attachment"
                                                                                        @change="onFileChange($event, 'payment_challan_attachment')"
                                                                                        accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col> -->
                                                                        <b-col sm="12">
                                                                            <ValidationProvider name="Place allotment letter for organizing the fair" vid="place_allotment_letter_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="place_allotment_letter_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(1) + '. ' + (checkBn ? 'মেলা আয়োজনের স্থান বরাদ্দ পত্র' : 'Place allotment letter for organizing the fair') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="place_allotment_letter_attachment"
                                                                                        v-model="formData.place_allotment_letter_attachment"
                                                                                        @change="onFileChange($event, 'place_allotment_letter_attachment')" accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 1">
                                                                            <ValidationProvider name="Up-to-date trade license" vid="trade_license_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="trade_license_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(2) + '. ' + (checkBn ? 'হালনাগাদ ট্রেড লাইসেন্স' : 'Up-to-date trade license') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="trade_license_attachment"
                                                                                        v-model="formData.trade_license_attachment"
                                                                                        @change="onFileChange($event, 'trade_license_attachment')"
                                                                                        accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 1">
                                                                            <ValidationProvider name="Updated income tax payment certificate" vid="income_tax_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="income_tax_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(3) + '. ' + (checkBn ? 'হালনাগাদ আয়কর পরিশোধ সনদ' : 'Updated income tax payment certificate') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="income_tax_attachment"
                                                                                        v-model="formData.income_tax_attachment"
                                                                                        @change="onFileChange($event, 'income_tax_attachment')"
                                                                                        accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 1">
                                                                            <ValidationProvider name="Up-to-date VAT payment certificate" vid="vat_certificate_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="vat_certificate_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(4) + '. ' + (checkBn ? 'হালনাগাদ ভ্যাট পরিশোধ সনদ' : 'Up-to-date VAT payment certificate') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="vat_certificate_attachment"
                                                                                        v-model="formData.vat_certificate_attachment"
                                                                                        @change="onFileChange($event, 'vat_certificate_attachment')" accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 1">
                                                                            <ValidationProvider name="Copy of application fee payment challan" vid="payment_challan_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="payment_challan_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(5) + '. ' + (checkBn ? 'পণ্য বা সেবা সংশ্লিষ্ট নিবন্ধিত এসোসিয়েশন/চেম্বার এর সুপারিশসহ মতামত' : 'Opinions including recommendations of registered associations/chambers regarding products or services') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="payment_challan_attachment"
                                                                                        v-model="formData.payment_challan_attachment"
                                                                                        @change="onFileChange($event, 'payment_challan_attachment')"
                                                                                        accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 2">
                                                                            <ValidationProvider name="TO License (in case of Association/Chamber/Society)" vid="to_license_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="to_license_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(2) + '. ' + (checkBn ? 'টিও লাইসেন্স (এসোসিয়েশন/চেম্বার/সমিতির ক্ষেত্রে)' : 'TO License (in case of Association/Chamber/Society)') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="to_license_attachment"
                                                                                        v-model="formData.to_license_attachment"
                                                                                        @change="onFileChange($event, 'to_license_attachment')" accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 2">
                                                                            <ValidationProvider name="Certificate of Incorporation (in case of Association/Chamber/Society)" vid="certificate_of_incorporation_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="certificate_of_incorporation_attachment" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(3) + '. ' + (checkBn ? 'সার্টিফিকেট অব ইনকর্পোরেশন (এসোসিয়েশন/চেম্বার/সমিতির ক্ষেত্রে)' : 'Certificate of Incorporation (in case of Association/Chamber/Society)') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="certificate_of_incorporation_attachment"
                                                                                        v-model="formData.certificate_of_incorporation_attachment"
                                                                                        @change="onFileChange($event, 'certificate_of_incorporation_attachment')" accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                        <b-col sm="12" v-if="formData.applicant_type === 2">
                                                                            <ValidationProvider name="Attachment" vid="noc_attachment" rules="required" v-slot="{ errors }">
                                                                                <b-form-group label-for="No objection of the concerned District Commissioner in organizing the international trade fair at the district level (if applicable)" label-cols-sm="4">
                                                                                    <template v-slot:label>
                                                                                        {{ $n(4) + '. ' + (checkBn ? 'জেলা পর্যায়ে আন্তর্জাতিক বাণিজ্য মেলা আয়োজনের ক্ষেত্রে সংশ্লিষ্ট জেলা প্রশাসকের অনাপত্তি (প্রযোজ্য ক্ষেত্রে)' : 'No objection of the concerned District Commissioner in organizing the international trade fair at the district level (if applicable)') }} <span class="text-danger">*</span>
                                                                                    </template>
                                                                                    <b-form-file
                                                                                        id="noc_attachment"
                                                                                        v-model="formData.noc_attachment"
                                                                                        @change="onFileChange($event, 'noc_attachment')" accept=".pdf"
                                                                                        plain
                                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                                    </b-form-file>
                                                                                    <div class="invalid-feedback">
                                                                                        {{ errors[0] }}
                                                                                    </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-col>
                                                                    </b-row>
                                                                </div>
                                                            </b-col>
                                                        </b-row>
                                                </b-card>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <b-row class="text-right">
                                <b-col>
                                    <b-button type="button" @click="submit(1)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                                    <b-button type="button" @click="submit(2)" variant="success" class="mr-2 btn-sm">{{ $t('teaGardenBtriService.submit_n_payment') }}</b-button>
                                    <!-- <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button> -->
                                    <router-link :title="$t('globalTrans.cancel')" variant="danger" to='/' class="btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
              </template>
             <!-- application end -->

            <!-- otp send modal start -->
              <!-- <div>
                <b-modal ref="mymodal" id="modal-4" size="md" :title="$t('globalTrans.otp')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <br/>
                    <b-overlay :show="loadModal">
                    <ValidationObserver ref="otp"  v-slot="{ handleSubmit, reset }">
                        <b-form id="otp" @submit.prevent="handleSubmit(otpVerify)" @reset.prevent="reset" >
                        <b-row>
                            <h2 class="col-lg-12 text-center" v-if="parseInt(timer) > 0">{{$t('globalTrans.timer')}} : {{ timer | minutesAndSeconds }}</h2>
                            <h2 class="col-lg-12 text-center" v-else>{{$t('globalTrans.please_resend_otp')}}</h2>
                            <b-col lg="12" sm="12">
                            <b-col lg="12" sm="12">
                                <ValidationProvider name="Otp"  vid="otp" rules="required">
                                <div slot-scope="{ valid, errors }">
                                    <b-form-input
                                    id="otp"
                                    v-model="otpCheck"
                                    :placeholder="$t('globalTrans.otp')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </div>
                                </ValidationProvider>
                            </b-col>
                            </b-col>
                            <br/>
                            <b-col lg="12" sm="12" style="padding-left: 30px;padding-top:10px">
                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.verify') }}</b-button>
                                <b-button type="button" variant="warning" class="mr-2 lg-3" @click="resendOtp">{{ $t('globalTrans.resend_code') }}</b-button>
                            </b-col>
                        </b-row>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
                <br/>
                </b-modal>
              </div> -->
               <!-- otp send modal end -->
            </body-card>
          </b-container>
        </div>
      </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { mapGetters } from 'vuex'
import { applicationPaymentDetails } from '../../../api/routes'
const FIVE_MINITES = 60 * 5
export default {
  name: 'Form',
  components: {},
  props: [],
  data () {
    return {
      baseUrl: internationalTradeFairServiceBaseUrl,
      valid: null,
      loading: false,
      errors: [],
      formData: {
      },
      otpCheck: '',
      countShow: false,
      FIVE_MINITES: FIVE_MINITES,
      timer: 0,
      loadModal: false
    }
  },
  computed: {
    ...mapGetters({
        commonProfile: 'Auth/commonProfile',
        authUser: 'Auth/authUser'
    }),
    currentLocale () {
      return this.$i18n.locale
    },
    checkBn () {
      return this.$i18n.locale === 'bn' ? 1 : 0
    },
    applicantTypeList () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'প্রতিষ্ঠান/কোম্পানি/ব্যক্তি' : 'Institute/Company/Person' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'এসোসিয়েশন/চেম্বার/সমিতি' : 'Association/Chamber/Committee' }
      ]
    },
    fairTypeList () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'আন্তর্জাতিক' : 'International' }
      ]
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getData(this.$route.query.id)
    } else {
      this.formData.applicant_name = this.$store.state.Auth.authUser.name_bn
      this.formData.applicant_mobile = this.$store.state.Auth.authUser.mobile
    }
  },
  filters: {
    minutesAndSeconds (value) {
        var minutes = Math.floor(parseInt(value, 10) / 60)
        var seconds = parseInt(value, 10) - minutes * 60
        return `${minutes}:${seconds}`
    }
  },
  mounted () {
    setInterval(() => {
        this.timer -= 1
    }, 1000)
  },
  methods: {
    async getData (id) {
        this.loader = true
          let result = null
          result = await RestApi.getData(internationalTradeFairServiceBaseUrl, `${applicationPaymentDetails}/${id}`)
          this.formData = result.data
          this.loader = false
      },
    onFileChange (event, property) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.formData[property] = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.formData[property] = ''
        }
    },
    finalSave () {
        this.$swal({
            title: this.$t('configuration.final_save_mess'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.submit()
            }
        })
    },
    async submit (status = 0) {
        var check = await this.$refs.form.validate()
        if (check) {
            this.loading = true
            this.formData.flag = status
            const result = await RestApi.postData(internationalTradeFairServiceBaseUrl, 'panel/dift-district-fair-application/store', this.formData)
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('portal.application_submit'),
                    color: '#D6E09B'
                })
                if (result.is_payment) {
                    const baseTableId = result.base_table_id
                    this.$router.push({ name: 'local_fair_panel.payment', params: { id: baseTableId }, query: { from: 'local_fair_panel.local_fair_application' } })
                } else {
                    this.$router.push({ name: 'local_fair_panel.local_fair_application' })
                }
                this.loading = false
                // this.$router.push('/local-fair-panel/local-fair-application')
            } else {
                this.loading = false
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Warning',
                    message: result.message,
                    color: '#e77171'
                })
            }
        }
    }
  }
}
</script>
<style scoped>
.application-form-wrapper .application-itmes .form-group .form-control, .application-form-wrapper .application-itmes .form-group .custom-select, .application-form-wrapper .application-itmes .form-group .custom-file-label {
    background-color: #fff;
    border-color: #dddddd;
}
.font-bn .btb_license_file_label .custom-file-label {
  font-size: 14px;
  color: #7c7c7c;
}
.validation_msg {
  font-size: 11px;
}
</style>
